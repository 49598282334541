@navHeightMobile: 60px;
@navHeight: 80px;

.navbar {
  .fixedFull; height: @navHeightMobile; z-index: 1000; background-color: @colorLightPink; border-bottom: 2px solid @colorPink; padding: 0; transition: 0.2s background-color ease-in-out, 0.2s height ease-in-out;
  &.nav-wrap.main { display: none; }
  &.nav-wrap.mobile {
    .navWrapper {
      display: flex; align-items: center; justify-content: space-between; min-width: 100%; height: @navHeightMobile; transition: 0.2s height ease-in-out; padding: 0 20px;
      .brand .navbar-brand { margin-right: 0; padding: 8px; }
      .navbar-toggler { position: absolute; right: 16px; border: none; color: @colorPink; font-size: 16px; letter-spacing: 2px; font-weight: 600; padding: 8px 16px; z-index: 5000; background-color: @colorBeige; }
    }
    .navbar-collapse {
      display: none;
      background-color: @colorLightPink;
      .navbar-nav {
        display: flex; align-items: center; flex-direction: column; justify-content: center; width: 100%; min-height: calc(100vh - 60px); padding: 0;
        .nav-item { flex: 0 1 auto; padding: 12px; }
        .nav-item span { color: @colorGreen; font-family: @font-family-default; font-size: 16px; font-weight: 600; text-transform: lowercase; letter-spacing: 1px; }
      }
      &.show {
        display: block;
      }
    }
  }

  @media @media-l-min {
    &.nav-wrap.mobile { display: none; }
    &.nav-wrap.main {
      display: block; min-width: 100%; background-color: transparent; border: none; height: 140px;
      .navWrapper {
        display: flex; align-items: center; justify-content: center; height: @navHeight; transition: 0.2s height ease-in-out; padding: 0 20px;
        .navbar-collapse {
          display: flex; align-items: center; justify-content: center; width: 100%; margin: 0;
          .navbar-nav {
            display: flex; align-items: center;
            .nav-item { flex: 0 1 auto; padding: 0 8px; }
            .nav-item span { color: @colorBlack; font-family: @font-family-default; text-transform: uppercase; font-size: 16px; font-weight: 700; letter-spacing: 1px; transition: 0.2s color ease-in; }
            .nav-item:hover span { cursor: pointer; color: @colorPink; }
            .nav-item ~ .nav-item { margin-left: 72px; }
            .nav-item.active span { color: @colorPink; }
          }
        }
      }
    }
    &.nav-wrap.scroll { background-repeat: no-repeat; background-size: cover; }
  }
}