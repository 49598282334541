// =====================================================================================================================
// ======================================================= MIXINS ======================================================
// =====================================================================================================================
.text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.absoluteFull() {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.fixedFull() {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bgCover(@x, @y) {
  background-size:cover;
  background-position:@x @y;
  background-repeat:no-repeat;
}

.clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

@font-size-base: 16;

.text-style(@font-size: @font-size-base) {
  @rem: round(@font-size / @font-size-base, 5);
  @doubleRem: @rem * 2;
  font-size: ~"@{rem}rem";
  line-height: ~"@{doubleRem}rem";
}

//@media @media-m-max {
//  font-size: .text-style(@font-size-base * 1px);
//}
//
//@media @media-l-min {
//  font-size: .text-style(@font-size-base * 1.125 * 1px);
//}