.tpl__mainHeader {
  .tpl {
    min-height: 87vh; background-size: cover; background-position: center;
    &:after {
      content: ''; position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-image: linear-gradient(to bottom, transparent, @colorPink); opacity: .5; 
    }
    .heading { position: absolute; left: 0; right: 0; bottom: 26vh; font-size: 30vw; opacity: 0.3; text-align: center; text-transform: lowercase; }
    .content {
      min-height: 65vh; display: inline-flex; justify-content: center; flex-direction: column; z-index: 1; position: absolute; right: 0; animation: animateText ease-out 1.5s;
      .textContent {
        background-color: rgba(35,37,45, 0.5); padding: 16px 32px 16px 16px; border-radius: 8px; 
        .text { 
          margin-bottom: 32px; font-size: 18px; letter-spacing: 1px; line-height: 1.3; color: @colorLightPink; font-style: italic; width: 75%;
          .delimiter { width: 250px; left: 0; margin-bottom: -12px; }
         }
       }
     }
     .overlay { height: 180px; }
  }

  @media @media-s-min {
    .tpl {
      .content .textContent {
        padding: 32px 64px 32px 32px;
        .text { font-size: 24px; }
      }
      .heading { bottom: 17vh; }
    }
  }

  @media @media-m-min {
    .tpl {
      .content .textContent .text { font-size: 32px; }
      .heading { bottom: 20vh; }
    }
  }

  @media @media-l-min {
    .tpl {
      .heading { bottom: 24vh; }
    }
  }
  @media @media-xl-min {
    .tpl {
      .heading { bottom: 25vh; }
    }
  }
}