/* =====================================================================================================================
====================================================== PAGE STYLES =====================================================
===================================================================================================================== */
/* =====================================================================================================================
============================================ Gridx SYSTEM BY TOMÁŠ JORPALIDIS ===========================================
===================================================================================================================== */
/* =====================================================================================================================
===================================================== gridx SETTINGS ====================================================
===================================================================================================================== */
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 5px;
  padding-right: 5px;
  width: 90%;
}
@media (min-width: 1680px) {
  .container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media (min-width: 768px) {
  .container {
    width: 730px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 960px;
  }
}
@media (min-width: 1280px) {
  .container {
    width: 1036px;
  }
  .container.container-medium {
    width: 1200px;
  }
}
@media (min-width: 1440px) {
  .container {
    width: 1092px;
  }
  .container.container-medium {
    width: 1360px;
  }
}
@media (min-width: 1680px) {
  .container.container-medium {
    width: 1450px;
  }
}
@media (min-width: 1920px) {
  .container.container-medium {
    width: 1660px;
  }
}
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 5px;
  padding-right: 5px;
}
@media (min-width: 1680px) {
  .container-fluid {
    padding-left: 6px;
    padding-right: 6px;
  }
}
.row {
  display: flex;
  margin-left: -5px;
  margin-right: -5px;
}
@media (min-width: 1680px) {
  .row {
    margin-left: -6px;
    margin-right: -6px;
  }
}
.grid-xs-1,
.grid-s-1,
.grid-m-1,
.grid-l-1,
.grid-xl-1,
.grid-xxl-1,
.grid-xs-2,
.grid-s-2,
.grid-m-2,
.grid-l-2,
.grid-xl-2,
.grid-xxl-2,
.grid-xs-3,
.grid-s-3,
.grid-m-3,
.grid-l-3,
.grid-xl-3,
.grid-xxl-3,
.grid-xs-4,
.grid-s-4,
.grid-m-4,
.grid-l-4,
.grid-xl-4,
.grid-xxl-4,
.grid-xs-5,
.grid-s-5,
.grid-m-5,
.grid-l-5,
.grid-xl-5,
.grid-xxl-5,
.grid-xs-6,
.grid-s-6,
.grid-m-6,
.grid-l-6,
.grid-xl-6,
.grid-xxl-6,
.grid-xs-7,
.grid-s-7,
.grid-m-7,
.grid-l-7,
.grid-xl-7,
.grid-xxl-7,
.grid-xs-8,
.grid-s-8,
.grid-m-8,
.grid-l-8,
.grid-xl-8,
.grid-xxl-8,
.grid-xs-9,
.grid-s-9,
.grid-m-9,
.grid-l-9,
.grid-xl-9,
.grid-xxl-9,
.grid-xs-10,
.grid-s-10,
.grid-m-10,
.grid-l-10,
.grid-xl-10,
.grid-xxl-10,
.grid-xs-11,
.grid-s-11,
.grid-m-11,
.grid-l-11,
.grid-xl-11,
.grid-xxl-11,
.grid-xs-12,
.grid-s-12,
.grid-m-12,
.grid-l-12,
.grid-xl-12,
.grid-xxl-12,
.grid-xs-13,
.grid-s-13,
.grid-m-13,
.grid-l-13,
.grid-xl-13,
.grid-xxl-13,
.grid-xs-14,
.grid-s-14,
.grid-m-14,
.grid-l-14,
.grid-xl-14,
.grid-xxl-14,
.grid-xs-15,
.grid-s-15,
.grid-m-15,
.grid-l-15,
.grid-xl-15,
.grid-xxl-15,
.grid-xs-16,
.grid-s-16,
.grid-m-16,
.grid-l-16,
.grid-xl-16,
.grid-xxl-16,
.grid-xs-17,
.grid-s-17,
.grid-m-17,
.grid-l-17,
.grid-xl-17,
.grid-xxl-17,
.grid-xs-18,
.grid-s-18,
.grid-m-18,
.grid-l-18,
.grid-xl-18,
.grid-xxl-18,
.grid-xs-19,
.grid-s-19,
.grid-m-19,
.grid-l-19,
.grid-xl-19,
.grid-xxl-19,
.grid-xs-20,
.grid-s-20,
.grid-m-20,
.grid-l-20,
.grid-xl-20,
.grid-xxl-20,
.grid-xs-21,
.grid-s-21,
.grid-m-21,
.grid-l-21,
.grid-xl-21,
.grid-xxl-21,
.grid-xs-22,
.grid-s-22,
.grid-m-22,
.grid-l-22,
.grid-xl-22,
.grid-xxl-22,
.grid-xs-23,
.grid-s-23,
.grid-m-23,
.grid-l-23,
.grid-xl-23,
.grid-xxl-23,
.grid-xs-24,
.grid-s-24,
.grid-m-24,
.grid-l-24,
.grid-xl-24,
.grid-xxl-24 {
  position: relative;
  min-height: 1px;
  padding-left: 5px;
  padding-right: 5px;
}
@media (min-width: 1680px) {
  .grid-xs-1,
  .grid-s-1,
  .grid-m-1,
  .grid-l-1,
  .grid-xl-1,
  .grid-xxl-1,
  .grid-xs-2,
  .grid-s-2,
  .grid-m-2,
  .grid-l-2,
  .grid-xl-2,
  .grid-xxl-2,
  .grid-xs-3,
  .grid-s-3,
  .grid-m-3,
  .grid-l-3,
  .grid-xl-3,
  .grid-xxl-3,
  .grid-xs-4,
  .grid-s-4,
  .grid-m-4,
  .grid-l-4,
  .grid-xl-4,
  .grid-xxl-4,
  .grid-xs-5,
  .grid-s-5,
  .grid-m-5,
  .grid-l-5,
  .grid-xl-5,
  .grid-xxl-5,
  .grid-xs-6,
  .grid-s-6,
  .grid-m-6,
  .grid-l-6,
  .grid-xl-6,
  .grid-xxl-6,
  .grid-xs-7,
  .grid-s-7,
  .grid-m-7,
  .grid-l-7,
  .grid-xl-7,
  .grid-xxl-7,
  .grid-xs-8,
  .grid-s-8,
  .grid-m-8,
  .grid-l-8,
  .grid-xl-8,
  .grid-xxl-8,
  .grid-xs-9,
  .grid-s-9,
  .grid-m-9,
  .grid-l-9,
  .grid-xl-9,
  .grid-xxl-9,
  .grid-xs-10,
  .grid-s-10,
  .grid-m-10,
  .grid-l-10,
  .grid-xl-10,
  .grid-xxl-10,
  .grid-xs-11,
  .grid-s-11,
  .grid-m-11,
  .grid-l-11,
  .grid-xl-11,
  .grid-xxl-11,
  .grid-xs-12,
  .grid-s-12,
  .grid-m-12,
  .grid-l-12,
  .grid-xl-12,
  .grid-xxl-12,
  .grid-xs-13,
  .grid-s-13,
  .grid-m-13,
  .grid-l-13,
  .grid-xl-13,
  .grid-xxl-13,
  .grid-xs-14,
  .grid-s-14,
  .grid-m-14,
  .grid-l-14,
  .grid-xl-14,
  .grid-xxl-14,
  .grid-xs-15,
  .grid-s-15,
  .grid-m-15,
  .grid-l-15,
  .grid-xl-15,
  .grid-xxl-15,
  .grid-xs-16,
  .grid-s-16,
  .grid-m-16,
  .grid-l-16,
  .grid-xl-16,
  .grid-xxl-16,
  .grid-xs-17,
  .grid-s-17,
  .grid-m-17,
  .grid-l-17,
  .grid-xl-17,
  .grid-xxl-17,
  .grid-xs-18,
  .grid-s-18,
  .grid-m-18,
  .grid-l-18,
  .grid-xl-18,
  .grid-xxl-18,
  .grid-xs-19,
  .grid-s-19,
  .grid-m-19,
  .grid-l-19,
  .grid-xl-19,
  .grid-xxl-19,
  .grid-xs-20,
  .grid-s-20,
  .grid-m-20,
  .grid-l-20,
  .grid-xl-20,
  .grid-xxl-20,
  .grid-xs-21,
  .grid-s-21,
  .grid-m-21,
  .grid-l-21,
  .grid-xl-21,
  .grid-xxl-21,
  .grid-xs-22,
  .grid-s-22,
  .grid-m-22,
  .grid-l-22,
  .grid-xl-22,
  .grid-xxl-22,
  .grid-xs-23,
  .grid-s-23,
  .grid-m-23,
  .grid-l-23,
  .grid-xl-23,
  .grid-xxl-23,
  .grid-xs-24,
  .grid-s-24,
  .grid-m-24,
  .grid-l-24,
  .grid-xl-24,
  .grid-xxl-24 {
    padding-left: 6px;
    padding-right: 6px;
  }
}
.grid-xs-1,
.grid-xs-2,
.grid-xs-3,
.grid-xs-4,
.grid-xs-5,
.grid-xs-6,
.grid-xs-7,
.grid-xs-8,
.grid-xs-9,
.grid-xs-10,
.grid-xs-11,
.grid-xs-12,
.grid-xs-13,
.grid-xs-14,
.grid-xs-15,
.grid-xs-16,
.grid-xs-17,
.grid-xs-18,
.grid-xs-19,
.grid-xs-20,
.grid-xs-21,
.grid-xs-22,
.grid-xs-23,
.grid-xs-24 {
  float: left;
}
.grid-xs-24 {
  width: 100%;
}
.grid-xs-23 {
  width: 95.83333333%;
}
.grid-xs-22 {
  width: 91.66666667%;
}
.grid-xs-21 {
  width: 87.5%;
}
.grid-xs-20 {
  width: 83.33333333%;
}
.grid-xs-19 {
  width: 79.16666667%;
}
.grid-xs-18 {
  width: 75%;
}
.grid-xs-17 {
  width: 70.83333333%;
}
.grid-xs-16 {
  width: 66.66666667%;
}
.grid-xs-15 {
  width: 62.5%;
}
.grid-xs-14 {
  width: 58.33333333%;
}
.grid-xs-13 {
  width: 54.16666667%;
}
.grid-xs-12 {
  width: 50%;
}
.grid-xs-11 {
  width: 45.83333333%;
}
.grid-xs-10 {
  width: 41.66666667%;
}
.grid-xs-9 {
  width: 37.5%;
}
.grid-xs-8 {
  width: 33.33333333%;
}
.grid-xs-7 {
  width: 29.16666667%;
}
.grid-xs-6 {
  width: 25%;
}
.grid-xs-5 {
  width: 20.83333333%;
}
.grid-xs-4 {
  width: 16.66666667%;
}
.grid-xs-3 {
  width: 12.5%;
}
.grid-xs-2 {
  width: 8.33333333%;
}
.grid-xs-1 {
  width: 4.16666667%;
}
.grid-xs-pull-24 {
  right: 100%;
}
.grid-xs-pull-23 {
  right: 95.83333333%;
}
.grid-xs-pull-22 {
  right: 91.66666667%;
}
.grid-xs-pull-21 {
  right: 87.5%;
}
.grid-xs-pull-20 {
  right: 83.33333333%;
}
.grid-xs-pull-19 {
  right: 79.16666667%;
}
.grid-xs-pull-18 {
  right: 75%;
}
.grid-xs-pull-17 {
  right: 70.83333333%;
}
.grid-xs-pull-16 {
  right: 66.66666667%;
}
.grid-xs-pull-15 {
  right: 62.5%;
}
.grid-xs-pull-14 {
  right: 58.33333333%;
}
.grid-xs-pull-13 {
  right: 54.16666667%;
}
.grid-xs-pull-12 {
  right: 50%;
}
.grid-xs-pull-11 {
  right: 45.83333333%;
}
.grid-xs-pull-10 {
  right: 41.66666667%;
}
.grid-xs-pull-9 {
  right: 37.5%;
}
.grid-xs-pull-8 {
  right: 33.33333333%;
}
.grid-xs-pull-7 {
  right: 29.16666667%;
}
.grid-xs-pull-6 {
  right: 25%;
}
.grid-xs-pull-5 {
  right: 20.83333333%;
}
.grid-xs-pull-4 {
  right: 16.66666667%;
}
.grid-xs-pull-3 {
  right: 12.5%;
}
.grid-xs-pull-2 {
  right: 8.33333333%;
}
.grid-xs-pull-1 {
  right: 4.16666667%;
}
.grid-xs-pull-0 {
  right: auto;
}
.grid-xs-push-24 {
  left: 100%;
}
.grid-xs-push-23 {
  left: 95.83333333%;
}
.grid-xs-push-22 {
  left: 91.66666667%;
}
.grid-xs-push-21 {
  left: 87.5%;
}
.grid-xs-push-20 {
  left: 83.33333333%;
}
.grid-xs-push-19 {
  left: 79.16666667%;
}
.grid-xs-push-18 {
  left: 75%;
}
.grid-xs-push-17 {
  left: 70.83333333%;
}
.grid-xs-push-16 {
  left: 66.66666667%;
}
.grid-xs-push-15 {
  left: 62.5%;
}
.grid-xs-push-14 {
  left: 58.33333333%;
}
.grid-xs-push-13 {
  left: 54.16666667%;
}
.grid-xs-push-12 {
  left: 50%;
}
.grid-xs-push-11 {
  left: 45.83333333%;
}
.grid-xs-push-10 {
  left: 41.66666667%;
}
.grid-xs-push-9 {
  left: 37.5%;
}
.grid-xs-push-8 {
  left: 33.33333333%;
}
.grid-xs-push-7 {
  left: 29.16666667%;
}
.grid-xs-push-6 {
  left: 25%;
}
.grid-xs-push-5 {
  left: 20.83333333%;
}
.grid-xs-push-4 {
  left: 16.66666667%;
}
.grid-xs-push-3 {
  left: 12.5%;
}
.grid-xs-push-2 {
  left: 8.33333333%;
}
.grid-xs-push-1 {
  left: 4.16666667%;
}
.grid-xs-push-0 {
  left: auto;
}
.grid-xs-offset-24 {
  margin-left: 100%;
}
.grid-xs-offset-23 {
  margin-left: 95.83333333%;
}
.grid-xs-offset-22 {
  margin-left: 91.66666667%;
}
.grid-xs-offset-21 {
  margin-left: 87.5%;
}
.grid-xs-offset-20 {
  margin-left: 83.33333333%;
}
.grid-xs-offset-19 {
  margin-left: 79.16666667%;
}
.grid-xs-offset-18 {
  margin-left: 75%;
}
.grid-xs-offset-17 {
  margin-left: 70.83333333%;
}
.grid-xs-offset-16 {
  margin-left: 66.66666667%;
}
.grid-xs-offset-15 {
  margin-left: 62.5%;
}
.grid-xs-offset-14 {
  margin-left: 58.33333333%;
}
.grid-xs-offset-13 {
  margin-left: 54.16666667%;
}
.grid-xs-offset-12 {
  margin-left: 50%;
}
.grid-xs-offset-11 {
  margin-left: 45.83333333%;
}
.grid-xs-offset-10 {
  margin-left: 41.66666667%;
}
.grid-xs-offset-9 {
  margin-left: 37.5%;
}
.grid-xs-offset-8 {
  margin-left: 33.33333333%;
}
.grid-xs-offset-7 {
  margin-left: 29.16666667%;
}
.grid-xs-offset-6 {
  margin-left: 25%;
}
.grid-xs-offset-5 {
  margin-left: 20.83333333%;
}
.grid-xs-offset-4 {
  margin-left: 16.66666667%;
}
.grid-xs-offset-3 {
  margin-left: 12.5%;
}
.grid-xs-offset-2 {
  margin-left: 8.33333333%;
}
.grid-xs-offset-1 {
  margin-left: 4.16666667%;
}
.grid-xs-offset-0 {
  margin-left: 0%;
}
@media (min-width: 500px) {
  .grid-s-1,
  .grid-s-2,
  .grid-s-3,
  .grid-s-4,
  .grid-s-5,
  .grid-s-6,
  .grid-s-7,
  .grid-s-8,
  .grid-s-9,
  .grid-s-10,
  .grid-s-11,
  .grid-s-12,
  .grid-s-13,
  .grid-s-14,
  .grid-s-15,
  .grid-s-16,
  .grid-s-17,
  .grid-s-18,
  .grid-s-19,
  .grid-s-20,
  .grid-s-21,
  .grid-s-22,
  .grid-s-23,
  .grid-s-24 {
    float: left;
  }
  .grid-s-24 {
    width: 100%;
  }
  .grid-s-23 {
    width: 95.83333333%;
  }
  .grid-s-22 {
    width: 91.66666667%;
  }
  .grid-s-21 {
    width: 87.5%;
  }
  .grid-s-20 {
    width: 83.33333333%;
  }
  .grid-s-19 {
    width: 79.16666667%;
  }
  .grid-s-18 {
    width: 75%;
  }
  .grid-s-17 {
    width: 70.83333333%;
  }
  .grid-s-16 {
    width: 66.66666667%;
  }
  .grid-s-15 {
    width: 62.5%;
  }
  .grid-s-14 {
    width: 58.33333333%;
  }
  .grid-s-13 {
    width: 54.16666667%;
  }
  .grid-s-12 {
    width: 50%;
  }
  .grid-s-11 {
    width: 45.83333333%;
  }
  .grid-s-10 {
    width: 41.66666667%;
  }
  .grid-s-9 {
    width: 37.5%;
  }
  .grid-s-8 {
    width: 33.33333333%;
  }
  .grid-s-7 {
    width: 29.16666667%;
  }
  .grid-s-6 {
    width: 25%;
  }
  .grid-s-5 {
    width: 20.83333333%;
  }
  .grid-s-4 {
    width: 16.66666667%;
  }
  .grid-s-3 {
    width: 12.5%;
  }
  .grid-s-2 {
    width: 8.33333333%;
  }
  .grid-s-1 {
    width: 4.16666667%;
  }
  .grid-s-pull-24 {
    right: 100%;
  }
  .grid-s-pull-23 {
    right: 95.83333333%;
  }
  .grid-s-pull-22 {
    right: 91.66666667%;
  }
  .grid-s-pull-21 {
    right: 87.5%;
  }
  .grid-s-pull-20 {
    right: 83.33333333%;
  }
  .grid-s-pull-19 {
    right: 79.16666667%;
  }
  .grid-s-pull-18 {
    right: 75%;
  }
  .grid-s-pull-17 {
    right: 70.83333333%;
  }
  .grid-s-pull-16 {
    right: 66.66666667%;
  }
  .grid-s-pull-15 {
    right: 62.5%;
  }
  .grid-s-pull-14 {
    right: 58.33333333%;
  }
  .grid-s-pull-13 {
    right: 54.16666667%;
  }
  .grid-s-pull-12 {
    right: 50%;
  }
  .grid-s-pull-11 {
    right: 45.83333333%;
  }
  .grid-s-pull-10 {
    right: 41.66666667%;
  }
  .grid-s-pull-9 {
    right: 37.5%;
  }
  .grid-s-pull-8 {
    right: 33.33333333%;
  }
  .grid-s-pull-7 {
    right: 29.16666667%;
  }
  .grid-s-pull-6 {
    right: 25%;
  }
  .grid-s-pull-5 {
    right: 20.83333333%;
  }
  .grid-s-pull-4 {
    right: 16.66666667%;
  }
  .grid-s-pull-3 {
    right: 12.5%;
  }
  .grid-s-pull-2 {
    right: 8.33333333%;
  }
  .grid-s-pull-1 {
    right: 4.16666667%;
  }
  .grid-s-pull-0 {
    right: auto;
  }
  .grid-s-push-24 {
    left: 100%;
  }
  .grid-s-push-23 {
    left: 95.83333333%;
  }
  .grid-s-push-22 {
    left: 91.66666667%;
  }
  .grid-s-push-21 {
    left: 87.5%;
  }
  .grid-s-push-20 {
    left: 83.33333333%;
  }
  .grid-s-push-19 {
    left: 79.16666667%;
  }
  .grid-s-push-18 {
    left: 75%;
  }
  .grid-s-push-17 {
    left: 70.83333333%;
  }
  .grid-s-push-16 {
    left: 66.66666667%;
  }
  .grid-s-push-15 {
    left: 62.5%;
  }
  .grid-s-push-14 {
    left: 58.33333333%;
  }
  .grid-s-push-13 {
    left: 54.16666667%;
  }
  .grid-s-push-12 {
    left: 50%;
  }
  .grid-s-push-11 {
    left: 45.83333333%;
  }
  .grid-s-push-10 {
    left: 41.66666667%;
  }
  .grid-s-push-9 {
    left: 37.5%;
  }
  .grid-s-push-8 {
    left: 33.33333333%;
  }
  .grid-s-push-7 {
    left: 29.16666667%;
  }
  .grid-s-push-6 {
    left: 25%;
  }
  .grid-s-push-5 {
    left: 20.83333333%;
  }
  .grid-s-push-4 {
    left: 16.66666667%;
  }
  .grid-s-push-3 {
    left: 12.5%;
  }
  .grid-s-push-2 {
    left: 8.33333333%;
  }
  .grid-s-push-1 {
    left: 4.16666667%;
  }
  .grid-s-push-0 {
    left: auto;
  }
  .grid-s-offset-24 {
    margin-left: 100%;
  }
  .grid-s-offset-23 {
    margin-left: 95.83333333%;
  }
  .grid-s-offset-22 {
    margin-left: 91.66666667%;
  }
  .grid-s-offset-21 {
    margin-left: 87.5%;
  }
  .grid-s-offset-20 {
    margin-left: 83.33333333%;
  }
  .grid-s-offset-19 {
    margin-left: 79.16666667%;
  }
  .grid-s-offset-18 {
    margin-left: 75%;
  }
  .grid-s-offset-17 {
    margin-left: 70.83333333%;
  }
  .grid-s-offset-16 {
    margin-left: 66.66666667%;
  }
  .grid-s-offset-15 {
    margin-left: 62.5%;
  }
  .grid-s-offset-14 {
    margin-left: 58.33333333%;
  }
  .grid-s-offset-13 {
    margin-left: 54.16666667%;
  }
  .grid-s-offset-12 {
    margin-left: 50%;
  }
  .grid-s-offset-11 {
    margin-left: 45.83333333%;
  }
  .grid-s-offset-10 {
    margin-left: 41.66666667%;
  }
  .grid-s-offset-9 {
    margin-left: 37.5%;
  }
  .grid-s-offset-8 {
    margin-left: 33.33333333%;
  }
  .grid-s-offset-7 {
    margin-left: 29.16666667%;
  }
  .grid-s-offset-6 {
    margin-left: 25%;
  }
  .grid-s-offset-5 {
    margin-left: 20.83333333%;
  }
  .grid-s-offset-4 {
    margin-left: 16.66666667%;
  }
  .grid-s-offset-3 {
    margin-left: 12.5%;
  }
  .grid-s-offset-2 {
    margin-left: 8.33333333%;
  }
  .grid-s-offset-1 {
    margin-left: 4.16666667%;
  }
  .grid-s-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 768px) {
  .grid-m-1,
  .grid-m-2,
  .grid-m-3,
  .grid-m-4,
  .grid-m-5,
  .grid-m-6,
  .grid-m-7,
  .grid-m-8,
  .grid-m-9,
  .grid-m-10,
  .grid-m-11,
  .grid-m-12,
  .grid-m-13,
  .grid-m-14,
  .grid-m-15,
  .grid-m-16,
  .grid-m-17,
  .grid-m-18,
  .grid-m-19,
  .grid-m-20,
  .grid-m-21,
  .grid-m-22,
  .grid-m-23,
  .grid-m-24 {
    float: left;
  }
  .grid-m-24 {
    width: 100%;
  }
  .grid-m-23 {
    width: 95.83333333%;
  }
  .grid-m-22 {
    width: 91.66666667%;
  }
  .grid-m-21 {
    width: 87.5%;
  }
  .grid-m-20 {
    width: 83.33333333%;
  }
  .grid-m-19 {
    width: 79.16666667%;
  }
  .grid-m-18 {
    width: 75%;
  }
  .grid-m-17 {
    width: 70.83333333%;
  }
  .grid-m-16 {
    width: 66.66666667%;
  }
  .grid-m-15 {
    width: 62.5%;
  }
  .grid-m-14 {
    width: 58.33333333%;
  }
  .grid-m-13 {
    width: 54.16666667%;
  }
  .grid-m-12 {
    width: 50%;
  }
  .grid-m-11 {
    width: 45.83333333%;
  }
  .grid-m-10 {
    width: 41.66666667%;
  }
  .grid-m-9 {
    width: 37.5%;
  }
  .grid-m-8 {
    width: 33.33333333%;
  }
  .grid-m-7 {
    width: 29.16666667%;
  }
  .grid-m-6 {
    width: 25%;
  }
  .grid-m-5 {
    width: 20.83333333%;
  }
  .grid-m-4 {
    width: 16.66666667%;
  }
  .grid-m-3 {
    width: 12.5%;
  }
  .grid-m-2 {
    width: 8.33333333%;
  }
  .grid-m-1 {
    width: 4.16666667%;
  }
  .grid-m-pull-24 {
    right: 100%;
  }
  .grid-m-pull-23 {
    right: 95.83333333%;
  }
  .grid-m-pull-22 {
    right: 91.66666667%;
  }
  .grid-m-pull-21 {
    right: 87.5%;
  }
  .grid-m-pull-20 {
    right: 83.33333333%;
  }
  .grid-m-pull-19 {
    right: 79.16666667%;
  }
  .grid-m-pull-18 {
    right: 75%;
  }
  .grid-m-pull-17 {
    right: 70.83333333%;
  }
  .grid-m-pull-16 {
    right: 66.66666667%;
  }
  .grid-m-pull-15 {
    right: 62.5%;
  }
  .grid-m-pull-14 {
    right: 58.33333333%;
  }
  .grid-m-pull-13 {
    right: 54.16666667%;
  }
  .grid-m-pull-12 {
    right: 50%;
  }
  .grid-m-pull-11 {
    right: 45.83333333%;
  }
  .grid-m-pull-10 {
    right: 41.66666667%;
  }
  .grid-m-pull-9 {
    right: 37.5%;
  }
  .grid-m-pull-8 {
    right: 33.33333333%;
  }
  .grid-m-pull-7 {
    right: 29.16666667%;
  }
  .grid-m-pull-6 {
    right: 25%;
  }
  .grid-m-pull-5 {
    right: 20.83333333%;
  }
  .grid-m-pull-4 {
    right: 16.66666667%;
  }
  .grid-m-pull-3 {
    right: 12.5%;
  }
  .grid-m-pull-2 {
    right: 8.33333333%;
  }
  .grid-m-pull-1 {
    right: 4.16666667%;
  }
  .grid-m-pull-0 {
    right: auto;
  }
  .grid-m-push-24 {
    left: 100%;
  }
  .grid-m-push-23 {
    left: 95.83333333%;
  }
  .grid-m-push-22 {
    left: 91.66666667%;
  }
  .grid-m-push-21 {
    left: 87.5%;
  }
  .grid-m-push-20 {
    left: 83.33333333%;
  }
  .grid-m-push-19 {
    left: 79.16666667%;
  }
  .grid-m-push-18 {
    left: 75%;
  }
  .grid-m-push-17 {
    left: 70.83333333%;
  }
  .grid-m-push-16 {
    left: 66.66666667%;
  }
  .grid-m-push-15 {
    left: 62.5%;
  }
  .grid-m-push-14 {
    left: 58.33333333%;
  }
  .grid-m-push-13 {
    left: 54.16666667%;
  }
  .grid-m-push-12 {
    left: 50%;
  }
  .grid-m-push-11 {
    left: 45.83333333%;
  }
  .grid-m-push-10 {
    left: 41.66666667%;
  }
  .grid-m-push-9 {
    left: 37.5%;
  }
  .grid-m-push-8 {
    left: 33.33333333%;
  }
  .grid-m-push-7 {
    left: 29.16666667%;
  }
  .grid-m-push-6 {
    left: 25%;
  }
  .grid-m-push-5 {
    left: 20.83333333%;
  }
  .grid-m-push-4 {
    left: 16.66666667%;
  }
  .grid-m-push-3 {
    left: 12.5%;
  }
  .grid-m-push-2 {
    left: 8.33333333%;
  }
  .grid-m-push-1 {
    left: 4.16666667%;
  }
  .grid-m-push-0 {
    left: auto;
  }
  .grid-m-offset-24 {
    margin-left: 100%;
  }
  .grid-m-offset-23 {
    margin-left: 95.83333333%;
  }
  .grid-m-offset-22 {
    margin-left: 91.66666667%;
  }
  .grid-m-offset-21 {
    margin-left: 87.5%;
  }
  .grid-m-offset-20 {
    margin-left: 83.33333333%;
  }
  .grid-m-offset-19 {
    margin-left: 79.16666667%;
  }
  .grid-m-offset-18 {
    margin-left: 75%;
  }
  .grid-m-offset-17 {
    margin-left: 70.83333333%;
  }
  .grid-m-offset-16 {
    margin-left: 66.66666667%;
  }
  .grid-m-offset-15 {
    margin-left: 62.5%;
  }
  .grid-m-offset-14 {
    margin-left: 58.33333333%;
  }
  .grid-m-offset-13 {
    margin-left: 54.16666667%;
  }
  .grid-m-offset-12 {
    margin-left: 50%;
  }
  .grid-m-offset-11 {
    margin-left: 45.83333333%;
  }
  .grid-m-offset-10 {
    margin-left: 41.66666667%;
  }
  .grid-m-offset-9 {
    margin-left: 37.5%;
  }
  .grid-m-offset-8 {
    margin-left: 33.33333333%;
  }
  .grid-m-offset-7 {
    margin-left: 29.16666667%;
  }
  .grid-m-offset-6 {
    margin-left: 25%;
  }
  .grid-m-offset-5 {
    margin-left: 20.83333333%;
  }
  .grid-m-offset-4 {
    margin-left: 16.66666667%;
  }
  .grid-m-offset-3 {
    margin-left: 12.5%;
  }
  .grid-m-offset-2 {
    margin-left: 8.33333333%;
  }
  .grid-m-offset-1 {
    margin-left: 4.16666667%;
  }
  .grid-m-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .grid-l-1,
  .grid-l-2,
  .grid-l-3,
  .grid-l-4,
  .grid-l-5,
  .grid-l-6,
  .grid-l-7,
  .grid-l-8,
  .grid-l-9,
  .grid-l-10,
  .grid-l-11,
  .grid-l-12,
  .grid-l-13,
  .grid-l-14,
  .grid-l-15,
  .grid-l-16,
  .grid-l-17,
  .grid-l-18,
  .grid-l-19,
  .grid-l-20,
  .grid-l-21,
  .grid-l-22,
  .grid-l-23,
  .grid-l-24 {
    float: left;
  }
  .grid-l-24 {
    width: 100%;
  }
  .grid-l-23 {
    width: 95.83333333%;
  }
  .grid-l-22 {
    width: 91.66666667%;
  }
  .grid-l-21 {
    width: 87.5%;
  }
  .grid-l-20 {
    width: 83.33333333%;
  }
  .grid-l-19 {
    width: 79.16666667%;
  }
  .grid-l-18 {
    width: 75%;
  }
  .grid-l-17 {
    width: 70.83333333%;
  }
  .grid-l-16 {
    width: 66.66666667%;
  }
  .grid-l-15 {
    width: 62.5%;
  }
  .grid-l-14 {
    width: 58.33333333%;
  }
  .grid-l-13 {
    width: 54.16666667%;
  }
  .grid-l-12 {
    width: 50%;
  }
  .grid-l-11 {
    width: 45.83333333%;
  }
  .grid-l-10 {
    width: 41.66666667%;
  }
  .grid-l-9 {
    width: 37.5%;
  }
  .grid-l-8 {
    width: 33.33333333%;
  }
  .grid-l-7 {
    width: 29.16666667%;
  }
  .grid-l-6 {
    width: 25%;
  }
  .grid-l-5 {
    width: 20.83333333%;
  }
  .grid-l-4 {
    width: 16.66666667%;
  }
  .grid-l-3 {
    width: 12.5%;
  }
  .grid-l-2 {
    width: 8.33333333%;
  }
  .grid-l-1 {
    width: 4.16666667%;
  }
  .grid-l-pull-24 {
    right: 100%;
  }
  .grid-l-pull-23 {
    right: 95.83333333%;
  }
  .grid-l-pull-22 {
    right: 91.66666667%;
  }
  .grid-l-pull-21 {
    right: 87.5%;
  }
  .grid-l-pull-20 {
    right: 83.33333333%;
  }
  .grid-l-pull-19 {
    right: 79.16666667%;
  }
  .grid-l-pull-18 {
    right: 75%;
  }
  .grid-l-pull-17 {
    right: 70.83333333%;
  }
  .grid-l-pull-16 {
    right: 66.66666667%;
  }
  .grid-l-pull-15 {
    right: 62.5%;
  }
  .grid-l-pull-14 {
    right: 58.33333333%;
  }
  .grid-l-pull-13 {
    right: 54.16666667%;
  }
  .grid-l-pull-12 {
    right: 50%;
  }
  .grid-l-pull-11 {
    right: 45.83333333%;
  }
  .grid-l-pull-10 {
    right: 41.66666667%;
  }
  .grid-l-pull-9 {
    right: 37.5%;
  }
  .grid-l-pull-8 {
    right: 33.33333333%;
  }
  .grid-l-pull-7 {
    right: 29.16666667%;
  }
  .grid-l-pull-6 {
    right: 25%;
  }
  .grid-l-pull-5 {
    right: 20.83333333%;
  }
  .grid-l-pull-4 {
    right: 16.66666667%;
  }
  .grid-l-pull-3 {
    right: 12.5%;
  }
  .grid-l-pull-2 {
    right: 8.33333333%;
  }
  .grid-l-pull-1 {
    right: 4.16666667%;
  }
  .grid-l-pull-0 {
    right: auto;
  }
  .grid-l-push-24 {
    left: 100%;
  }
  .grid-l-push-23 {
    left: 95.83333333%;
  }
  .grid-l-push-22 {
    left: 91.66666667%;
  }
  .grid-l-push-21 {
    left: 87.5%;
  }
  .grid-l-push-20 {
    left: 83.33333333%;
  }
  .grid-l-push-19 {
    left: 79.16666667%;
  }
  .grid-l-push-18 {
    left: 75%;
  }
  .grid-l-push-17 {
    left: 70.83333333%;
  }
  .grid-l-push-16 {
    left: 66.66666667%;
  }
  .grid-l-push-15 {
    left: 62.5%;
  }
  .grid-l-push-14 {
    left: 58.33333333%;
  }
  .grid-l-push-13 {
    left: 54.16666667%;
  }
  .grid-l-push-12 {
    left: 50%;
  }
  .grid-l-push-11 {
    left: 45.83333333%;
  }
  .grid-l-push-10 {
    left: 41.66666667%;
  }
  .grid-l-push-9 {
    left: 37.5%;
  }
  .grid-l-push-8 {
    left: 33.33333333%;
  }
  .grid-l-push-7 {
    left: 29.16666667%;
  }
  .grid-l-push-6 {
    left: 25%;
  }
  .grid-l-push-5 {
    left: 20.83333333%;
  }
  .grid-l-push-4 {
    left: 16.66666667%;
  }
  .grid-l-push-3 {
    left: 12.5%;
  }
  .grid-l-push-2 {
    left: 8.33333333%;
  }
  .grid-l-push-1 {
    left: 4.16666667%;
  }
  .grid-l-push-0 {
    left: auto;
  }
  .grid-l-offset-24 {
    margin-left: 100%;
  }
  .grid-l-offset-23 {
    margin-left: 95.83333333%;
  }
  .grid-l-offset-22 {
    margin-left: 91.66666667%;
  }
  .grid-l-offset-21 {
    margin-left: 87.5%;
  }
  .grid-l-offset-20 {
    margin-left: 83.33333333%;
  }
  .grid-l-offset-19 {
    margin-left: 79.16666667%;
  }
  .grid-l-offset-18 {
    margin-left: 75%;
  }
  .grid-l-offset-17 {
    margin-left: 70.83333333%;
  }
  .grid-l-offset-16 {
    margin-left: 66.66666667%;
  }
  .grid-l-offset-15 {
    margin-left: 62.5%;
  }
  .grid-l-offset-14 {
    margin-left: 58.33333333%;
  }
  .grid-l-offset-13 {
    margin-left: 54.16666667%;
  }
  .grid-l-offset-12 {
    margin-left: 50%;
  }
  .grid-l-offset-11 {
    margin-left: 45.83333333%;
  }
  .grid-l-offset-10 {
    margin-left: 41.66666667%;
  }
  .grid-l-offset-9 {
    margin-left: 37.5%;
  }
  .grid-l-offset-8 {
    margin-left: 33.33333333%;
  }
  .grid-l-offset-7 {
    margin-left: 29.16666667%;
  }
  .grid-l-offset-6 {
    margin-left: 25%;
  }
  .grid-l-offset-5 {
    margin-left: 20.83333333%;
  }
  .grid-l-offset-4 {
    margin-left: 16.66666667%;
  }
  .grid-l-offset-3 {
    margin-left: 12.5%;
  }
  .grid-l-offset-2 {
    margin-left: 8.33333333%;
  }
  .grid-l-offset-1 {
    margin-left: 4.16666667%;
  }
  .grid-l-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .grid-xl-1,
  .grid-xl-2,
  .grid-xl-3,
  .grid-xl-4,
  .grid-xl-5,
  .grid-xl-6,
  .grid-xl-7,
  .grid-xl-8,
  .grid-xl-9,
  .grid-xl-10,
  .grid-xl-11,
  .grid-xl-12,
  .grid-xl-13,
  .grid-xl-14,
  .grid-xl-15,
  .grid-xl-16,
  .grid-xl-17,
  .grid-xl-18,
  .grid-xl-19,
  .grid-xl-20,
  .grid-xl-21,
  .grid-xl-22,
  .grid-xl-23,
  .grid-xl-24 {
    float: left;
  }
  .grid-xl-24 {
    width: 100%;
  }
  .grid-xl-23 {
    width: 95.83333333%;
  }
  .grid-xl-22 {
    width: 91.66666667%;
  }
  .grid-xl-21 {
    width: 87.5%;
  }
  .grid-xl-20 {
    width: 83.33333333%;
  }
  .grid-xl-19 {
    width: 79.16666667%;
  }
  .grid-xl-18 {
    width: 75%;
  }
  .grid-xl-17 {
    width: 70.83333333%;
  }
  .grid-xl-16 {
    width: 66.66666667%;
  }
  .grid-xl-15 {
    width: 62.5%;
  }
  .grid-xl-14 {
    width: 58.33333333%;
  }
  .grid-xl-13 {
    width: 54.16666667%;
  }
  .grid-xl-12 {
    width: 50%;
  }
  .grid-xl-11 {
    width: 45.83333333%;
  }
  .grid-xl-10 {
    width: 41.66666667%;
  }
  .grid-xl-9 {
    width: 37.5%;
  }
  .grid-xl-8 {
    width: 33.33333333%;
  }
  .grid-xl-7 {
    width: 29.16666667%;
  }
  .grid-xl-6 {
    width: 25%;
  }
  .grid-xl-5 {
    width: 20.83333333%;
  }
  .grid-xl-4 {
    width: 16.66666667%;
  }
  .grid-xl-3 {
    width: 12.5%;
  }
  .grid-xl-2 {
    width: 8.33333333%;
  }
  .grid-xl-1 {
    width: 4.16666667%;
  }
  .grid-xl-pull-24 {
    right: 100%;
  }
  .grid-xl-pull-23 {
    right: 95.83333333%;
  }
  .grid-xl-pull-22 {
    right: 91.66666667%;
  }
  .grid-xl-pull-21 {
    right: 87.5%;
  }
  .grid-xl-pull-20 {
    right: 83.33333333%;
  }
  .grid-xl-pull-19 {
    right: 79.16666667%;
  }
  .grid-xl-pull-18 {
    right: 75%;
  }
  .grid-xl-pull-17 {
    right: 70.83333333%;
  }
  .grid-xl-pull-16 {
    right: 66.66666667%;
  }
  .grid-xl-pull-15 {
    right: 62.5%;
  }
  .grid-xl-pull-14 {
    right: 58.33333333%;
  }
  .grid-xl-pull-13 {
    right: 54.16666667%;
  }
  .grid-xl-pull-12 {
    right: 50%;
  }
  .grid-xl-pull-11 {
    right: 45.83333333%;
  }
  .grid-xl-pull-10 {
    right: 41.66666667%;
  }
  .grid-xl-pull-9 {
    right: 37.5%;
  }
  .grid-xl-pull-8 {
    right: 33.33333333%;
  }
  .grid-xl-pull-7 {
    right: 29.16666667%;
  }
  .grid-xl-pull-6 {
    right: 25%;
  }
  .grid-xl-pull-5 {
    right: 20.83333333%;
  }
  .grid-xl-pull-4 {
    right: 16.66666667%;
  }
  .grid-xl-pull-3 {
    right: 12.5%;
  }
  .grid-xl-pull-2 {
    right: 8.33333333%;
  }
  .grid-xl-pull-1 {
    right: 4.16666667%;
  }
  .grid-xl-pull-0 {
    right: auto;
  }
  .grid-xl-push-24 {
    left: 100%;
  }
  .grid-xl-push-23 {
    left: 95.83333333%;
  }
  .grid-xl-push-22 {
    left: 91.66666667%;
  }
  .grid-xl-push-21 {
    left: 87.5%;
  }
  .grid-xl-push-20 {
    left: 83.33333333%;
  }
  .grid-xl-push-19 {
    left: 79.16666667%;
  }
  .grid-xl-push-18 {
    left: 75%;
  }
  .grid-xl-push-17 {
    left: 70.83333333%;
  }
  .grid-xl-push-16 {
    left: 66.66666667%;
  }
  .grid-xl-push-15 {
    left: 62.5%;
  }
  .grid-xl-push-14 {
    left: 58.33333333%;
  }
  .grid-xl-push-13 {
    left: 54.16666667%;
  }
  .grid-xl-push-12 {
    left: 50%;
  }
  .grid-xl-push-11 {
    left: 45.83333333%;
  }
  .grid-xl-push-10 {
    left: 41.66666667%;
  }
  .grid-xl-push-9 {
    left: 37.5%;
  }
  .grid-xl-push-8 {
    left: 33.33333333%;
  }
  .grid-xl-push-7 {
    left: 29.16666667%;
  }
  .grid-xl-push-6 {
    left: 25%;
  }
  .grid-xl-push-5 {
    left: 20.83333333%;
  }
  .grid-xl-push-4 {
    left: 16.66666667%;
  }
  .grid-xl-push-3 {
    left: 12.5%;
  }
  .grid-xl-push-2 {
    left: 8.33333333%;
  }
  .grid-xl-push-1 {
    left: 4.16666667%;
  }
  .grid-xl-push-0 {
    left: auto;
  }
  .grid-xl-offset-24 {
    margin-left: 100%;
  }
  .grid-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .grid-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .grid-xl-offset-21 {
    margin-left: 87.5%;
  }
  .grid-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .grid-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .grid-xl-offset-18 {
    margin-left: 75%;
  }
  .grid-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .grid-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .grid-xl-offset-15 {
    margin-left: 62.5%;
  }
  .grid-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .grid-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .grid-xl-offset-12 {
    margin-left: 50%;
  }
  .grid-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .grid-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .grid-xl-offset-9 {
    margin-left: 37.5%;
  }
  .grid-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .grid-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .grid-xl-offset-6 {
    margin-left: 25%;
  }
  .grid-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .grid-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .grid-xl-offset-3 {
    margin-left: 12.5%;
  }
  .grid-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .grid-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .grid-xl-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1400px) {
  .grid-xxl-1,
  .grid-xxl-2,
  .grid-xxl-3,
  .grid-xxl-4,
  .grid-xxl-5,
  .grid-xxl-6,
  .grid-xxl-7,
  .grid-xxl-8,
  .grid-xxl-9,
  .grid-xxl-10,
  .grid-xxl-11,
  .grid-xxl-12,
  .grid-xxl-13,
  .grid-xxl-14,
  .grid-xxl-15,
  .grid-xxl-16,
  .grid-xxl-17,
  .grid-xxl-18,
  .grid-xxl-19,
  .grid-xxl-20,
  .grid-xxl-21,
  .grid-xxl-22,
  .grid-xxl-23,
  .grid-xxl-24 {
    float: left;
  }
  .grid-xxl-24 {
    width: 100%;
  }
  .grid-xxl-23 {
    width: 95.83333333%;
  }
  .grid-xxl-22 {
    width: 91.66666667%;
  }
  .grid-xxl-21 {
    width: 87.5%;
  }
  .grid-xxl-20 {
    width: 83.33333333%;
  }
  .grid-xxl-19 {
    width: 79.16666667%;
  }
  .grid-xxl-18 {
    width: 75%;
  }
  .grid-xxl-17 {
    width: 70.83333333%;
  }
  .grid-xxl-16 {
    width: 66.66666667%;
  }
  .grid-xxl-15 {
    width: 62.5%;
  }
  .grid-xxl-14 {
    width: 58.33333333%;
  }
  .grid-xxl-13 {
    width: 54.16666667%;
  }
  .grid-xxl-12 {
    width: 50%;
  }
  .grid-xxl-11 {
    width: 45.83333333%;
  }
  .grid-xxl-10 {
    width: 41.66666667%;
  }
  .grid-xxl-9 {
    width: 37.5%;
  }
  .grid-xxl-8 {
    width: 33.33333333%;
  }
  .grid-xxl-7 {
    width: 29.16666667%;
  }
  .grid-xxl-6 {
    width: 25%;
  }
  .grid-xxl-5 {
    width: 20.83333333%;
  }
  .grid-xxl-4 {
    width: 16.66666667%;
  }
  .grid-xxl-3 {
    width: 12.5%;
  }
  .grid-xxl-2 {
    width: 8.33333333%;
  }
  .grid-xxl-1 {
    width: 4.16666667%;
  }
  .grid-xxl-pull-24 {
    right: 100%;
  }
  .grid-xxl-pull-23 {
    right: 95.83333333%;
  }
  .grid-xxl-pull-22 {
    right: 91.66666667%;
  }
  .grid-xxl-pull-21 {
    right: 87.5%;
  }
  .grid-xxl-pull-20 {
    right: 83.33333333%;
  }
  .grid-xxl-pull-19 {
    right: 79.16666667%;
  }
  .grid-xxl-pull-18 {
    right: 75%;
  }
  .grid-xxl-pull-17 {
    right: 70.83333333%;
  }
  .grid-xxl-pull-16 {
    right: 66.66666667%;
  }
  .grid-xxl-pull-15 {
    right: 62.5%;
  }
  .grid-xxl-pull-14 {
    right: 58.33333333%;
  }
  .grid-xxl-pull-13 {
    right: 54.16666667%;
  }
  .grid-xxl-pull-12 {
    right: 50%;
  }
  .grid-xxl-pull-11 {
    right: 45.83333333%;
  }
  .grid-xxl-pull-10 {
    right: 41.66666667%;
  }
  .grid-xxl-pull-9 {
    right: 37.5%;
  }
  .grid-xxl-pull-8 {
    right: 33.33333333%;
  }
  .grid-xxl-pull-7 {
    right: 29.16666667%;
  }
  .grid-xxl-pull-6 {
    right: 25%;
  }
  .grid-xxl-pull-5 {
    right: 20.83333333%;
  }
  .grid-xxl-pull-4 {
    right: 16.66666667%;
  }
  .grid-xxl-pull-3 {
    right: 12.5%;
  }
  .grid-xxl-pull-2 {
    right: 8.33333333%;
  }
  .grid-xxl-pull-1 {
    right: 4.16666667%;
  }
  .grid-xxl-pull-0 {
    right: auto;
  }
  .grid-xxl-push-24 {
    left: 100%;
  }
  .grid-xxl-push-23 {
    left: 95.83333333%;
  }
  .grid-xxl-push-22 {
    left: 91.66666667%;
  }
  .grid-xxl-push-21 {
    left: 87.5%;
  }
  .grid-xxl-push-20 {
    left: 83.33333333%;
  }
  .grid-xxl-push-19 {
    left: 79.16666667%;
  }
  .grid-xxl-push-18 {
    left: 75%;
  }
  .grid-xxl-push-17 {
    left: 70.83333333%;
  }
  .grid-xxl-push-16 {
    left: 66.66666667%;
  }
  .grid-xxl-push-15 {
    left: 62.5%;
  }
  .grid-xxl-push-14 {
    left: 58.33333333%;
  }
  .grid-xxl-push-13 {
    left: 54.16666667%;
  }
  .grid-xxl-push-12 {
    left: 50%;
  }
  .grid-xxl-push-11 {
    left: 45.83333333%;
  }
  .grid-xxl-push-10 {
    left: 41.66666667%;
  }
  .grid-xxl-push-9 {
    left: 37.5%;
  }
  .grid-xxl-push-8 {
    left: 33.33333333%;
  }
  .grid-xxl-push-7 {
    left: 29.16666667%;
  }
  .grid-xxl-push-6 {
    left: 25%;
  }
  .grid-xxl-push-5 {
    left: 20.83333333%;
  }
  .grid-xxl-push-4 {
    left: 16.66666667%;
  }
  .grid-xxl-push-3 {
    left: 12.5%;
  }
  .grid-xxl-push-2 {
    left: 8.33333333%;
  }
  .grid-xxl-push-1 {
    left: 4.16666667%;
  }
  .grid-xxl-push-0 {
    left: auto;
  }
  .grid-xxl-offset-24 {
    margin-left: 100%;
  }
  .grid-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .grid-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .grid-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .grid-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .grid-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .grid-xxl-offset-18 {
    margin-left: 75%;
  }
  .grid-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .grid-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .grid-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .grid-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .grid-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .grid-xxl-offset-12 {
    margin-left: 50%;
  }
  .grid-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .grid-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .grid-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .grid-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .grid-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .grid-xxl-offset-6 {
    margin-left: 25%;
  }
  .grid-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .grid-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .grid-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .grid-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .grid-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .grid-xxl-offset-0 {
    margin-left: 0%;
  }
}
/* =====================================================================================================================
================================================== MIXINS & FUNCTIONS ==================================================
===================================================================================================================== */
.tpl.tpl--gray .row.row-flex {
  flex-direction: column-reverse;
}
@media (min-width: 992px) {
  .tpl.tpl--gray .row.row-flex {
    flex-direction: row;
  }
}
.row-flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}
/* =====================================================================================================================
====================================================== DEBUG MODE ======================================================
===================================================================================================================== */
#tracy-debug:after {
  -webkit-user-drag: element;
  content: "XS";
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  background: #cc0000;
  padding: 5px 15px;
  text-align: center;
  font-weight: bold;
  color: #fff;
  pointer-events: none;
}
@media (min-width: 500px) {
  #tracy-debug:after {
    content: "S";
  }
}
@media (min-width: 768px) {
  #tracy-debug:after {
    content: "M";
  }
}
@media (min-width: 992px) {
  #tracy-debug:after {
    content: "L";
  }
}
@media (min-width: 1200px) {
  #tracy-debug:after {
    content: "XL";
  }
}
@media (min-width: 1400px) {
  #tracy-debug:after {
    content: "XXL";
  }
}
#tracy-debug:after:hover {
  opacity: 0.1;
}
html {
  scroll-behavior: smooth;
  --scrollbarBG: #414342;
  --thumbBG: #ddb7ab;
}
html,
body {
  line-height: 26px;
  font-weight: 600;
  font-family: 'Cormorant Garamond', serif, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.3px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  padding: 0!important;
}
html.overflow,
body.overflow {
  overflow: unset;
  height: 100%;
}
body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  position: relative;
  margin: 0;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body *:focus {
  outline: none;
}
* {
  box-sizing: border-box;
  margin: 0;
}
.h100p {
  height: 100%;
}
div,
section,
article,
header,
figure,
nav,
footer,
label,
main,
button,
aside,
form {
  position: relative;
}
img {
  max-width: 100%;
}
a {
  position: relative;
  text-decoration: none;
  transition: color 0.25s ease-out;
  font-weight: 400;
}
a:hover,
a:focus {
  text-decoration: none;
}
p {
  color: #414342;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 600;
  line-height: 1.5;
  margin: 5px auto;
  text-align: justify;
  hyphens: auto;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Cormorant Garamond', serif, sans-serif;
}
h1 {
  font-size: 60px;
}
h2 {
  font-size: 48px;
}
h3 {
  font-size: 36px;
}
h4 {
  font-size: 30px;
}
h5 {
  font-size: 24px;
}
.CookieConsent {
  padding: 8px 16px;
  border-top: 2px solid #414342;
}
.CookieConsent .content {
  color: #414342;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  font-family: 'Cormorant Garamond', serif;
}
@media (min-width: 992px) {
  html,
  body {
    font-size: 20px;
    line-height: 32px;
  }
  h1 {
    font-size: 72px;
  }
  h2 {
    font-size: 60px;
  }
  h3 {
    font-size: 48px;
  }
  h4 {
    font-size: 36px;
  }
  h5 {
    font-size: 30px;
  }
  p {
    font-size: 20px;
  }
}
.page-wrap {
  flex: 1 0 auto;
  overflow: hidden;
  padding-top: 0;
  width: 100%;
  background-color: inherit;
  animation: pageWrap 1s ease-in;
}
body.overlay {
  transition: all 2s ease-in-out;
  overflow: hidden;
}
#nette-debug:after {
  -webkit-user-drag: element;
  content: "XS";
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  background: #cc0000;
  padding: 5px 15px;
  text-align: center;
  font-weight: bold;
  color: #fff;
  pointer-events: none;
}
@media (min-width: 500px) {
  #nette-debug:after {
    content: "S";
  }
}
@media (min-width: 768px) {
  #nette-debug:after {
    content: "M";
  }
}
@media (min-width: 992px) {
  #nette-debug:after {
    content: "L";
  }
}
@media (min-width: 1200px) {
  #nette-debug:after {
    content: "XL";
  }
}
@media (min-width: 1400px) {
  #nette-debug:after {
    content: "XXL";
  }
}
#nette-debug:after:hover {
  opacity: 0.1;
}
.overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 180px;
  background-size: cover;
  background-repeat: no-repeat;
}
.text.text--pink {
  color: #ddb7ab;
}
.text.text--beige {
  color: #f4eeed;
}
.text.text--green {
  color: #999b84;
}
.text.text--lightPink {
  color: #efd9d1;
}
.text.text--vertical {
  transform: rotate(-90deg);
  white-space: nowrap;
}
.delimiter {
  width: 100px;
  height: 3px;
  background-color: #414342;
  position: absolute;
  bottom: 0;
  right: 0;
}
.delimiter.delimiter--pink {
  background-color: #ddb7ab;
}
.delimiter.delimiter--beige {
  background-color: #f4eeed;
}
.delimiter.delimiter--green {
  background-color: #999b84;
}
.delimiter.delimiter--lightPink {
  background-color: #efd9d1;
}
.gold {
  position: absolute;
  left: 0;
  right: 0;
  top: -100px;
  bottom: 0;
  opacity: 0.4;
}
strong {
  font-weight: 800;
}
/* =====================================================================================================================
=================================================== MEDIUM CONTAINER ===================================================
===================================================================================================================== */
.container-medium {
  margin-right: auto;
  margin-left: auto;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100%;
}
@media (min-width: 1680px) {
  .container-medium {
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media (max-width: 1399px) {
  .container-medium {
    width: auto;
    margin-left: 15px;
    margin-right: 25px;
  }
}
@media (min-width: 1400px) {
  .container-medium {
    width: 1270px;
  }
}
@media (min-width: 1200px) {
  .container-fluid {
    width: 100%;
  }
}
.btn {
  border: 0;
  outline: 0;
  display: inline-block;
  font-size: 16px;
  letter-spacing: 2px;
  border-radius: 16px;
  padding: 8px 16px;
  font-family: 'Cormorant Garamond', serif;
  font-weight: 700;
  color: #ddb7ab;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  text-transform: uppercase;
}
.btn.btn--lightWhite {
  background-color: #f4eeed;
  font-family: 'Cormorant Garamond', serif;
  border: 3px solid #ddb7ab;
}
.btn.btn--lightWhite:hover {
  color: #f4eeed;
  background-color: #ddb7ab;
  border-color: #f4eeed;
}
.btn.btn--cookie {
  padding: 8px 16px;
  color: #ddb7ab;
  margin: 0 16px;
}
.btn.btn--decline {
  font-size: 12px;
  border-bottom: 2px solid #ddb7ab;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  margin: 0 16px;
}
.btn.btn--decline:hover {
  color: #efd9d1;
  border-color: #efd9d1;
}
@media (min-width: 768px) {
  .btn {
    padding: 16px 32px;
  }
}
@media (min-width: 992px) {
  .btn {
    font-size: 16px;
    padding: 12px 32px;
  }
}
/* LIBS */
@keyframes animateText {
  0% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* PAGE */
.navbar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  z-index: 1000;
  background-color: #efd9d1;
  border-bottom: 2px solid #ddb7ab;
  padding: 0;
  transition: 0.2s background-color ease-in-out, 0.2s height ease-in-out;
}
.navbar.nav-wrap.main {
  display: none;
}
.navbar.nav-wrap.mobile .navWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  height: 60px;
  transition: 0.2s height ease-in-out;
  padding: 0 20px;
}
.navbar.nav-wrap.mobile .navWrapper .brand .navbar-brand {
  margin-right: 0;
  padding: 8px;
}
.navbar.nav-wrap.mobile .navWrapper .navbar-toggler {
  position: absolute;
  right: 16px;
  border: none;
  color: #ddb7ab;
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 600;
  padding: 8px 16px;
  z-index: 5000;
  background-color: #f4eeed;
}
.navbar.nav-wrap.mobile .navbar-collapse {
  display: none;
  background-color: #efd9d1;
}
.navbar.nav-wrap.mobile .navbar-collapse .navbar-nav {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - 60px);
  padding: 0;
}
.navbar.nav-wrap.mobile .navbar-collapse .navbar-nav .nav-item {
  flex: 0 1 auto;
  padding: 12px;
}
.navbar.nav-wrap.mobile .navbar-collapse .navbar-nav .nav-item span {
  color: #999b84;
  font-family: 'Cormorant Garamond', serif;
  font-size: 16px;
  font-weight: 600;
  text-transform: lowercase;
  letter-spacing: 1px;
}
.navbar.nav-wrap.mobile .navbar-collapse.show {
  display: block;
}
@media (min-width: 992px) {
  .navbar.nav-wrap.mobile {
    display: none;
  }
  .navbar.nav-wrap.main {
    display: block;
    min-width: 100%;
    background-color: transparent;
    border: none;
    height: 140px;
  }
  .navbar.nav-wrap.main .navWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    transition: 0.2s height ease-in-out;
    padding: 0 20px;
  }
  .navbar.nav-wrap.main .navWrapper .navbar-collapse {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
  }
  .navbar.nav-wrap.main .navWrapper .navbar-collapse .navbar-nav {
    display: flex;
    align-items: center;
  }
  .navbar.nav-wrap.main .navWrapper .navbar-collapse .navbar-nav .nav-item {
    flex: 0 1 auto;
    padding: 0 8px;
  }
  .navbar.nav-wrap.main .navWrapper .navbar-collapse .navbar-nav .nav-item span {
    color: #414342;
    font-family: 'Cormorant Garamond', serif;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
    transition: 0.2s color ease-in;
  }
  .navbar.nav-wrap.main .navWrapper .navbar-collapse .navbar-nav .nav-item:hover span {
    cursor: pointer;
    color: #ddb7ab;
  }
  .navbar.nav-wrap.main .navWrapper .navbar-collapse .navbar-nav .nav-item ~ .nav-item {
    margin-left: 72px;
  }
  .navbar.nav-wrap.main .navWrapper .navbar-collapse .navbar-nav .nav-item.active span {
    color: #ddb7ab;
  }
  .navbar.nav-wrap.scroll {
    background-repeat: no-repeat;
    background-size: cover;
  }
}
/* COMPONENTS */
.comp__heading {
  display: inline-block;
  margin-bottom: 16px;
}
.comp__heading .heading {
  display: inline-block;
  font-family: 'Cormorant Garamond', serif;
  font-size: 36px;
  line-height: 1.1;
  margin: 0;
  margin-bottom: 12px;
  text-transform: uppercase;
  animation: fade-in 1.5s both;
}
.comp__heading .heading.heading--pink {
  color: #ddb7ab;
}
.comp__heading .heading.heading--beige {
  color: #f4eeed;
}
.comp__heading .heading.heading--green {
  color: #999b84;
}
.comp__heading .heading.heading--lightPink {
  color: #efd9d1;
}
@media (min-width: 768px) {
  .comp__heading {
    margin-bottom: 0;
  }
  .comp__heading .heading {
    font-size: 48px;
    margin-bottom: 32px;
  }
}
@media (min-width: 992px) {
  .comp__heading .heading {
    font-size: 60px;
  }
}
.comp__contactForm {
  margin: 32px 0;
}
.comp__contactForm .formTitle {
  font-size: 24px;
  color: #f4eeed;
  line-height: 1.5;
  margin-bottom: 32px;
}
.comp__contactForm .items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.comp__contactForm .items .item {
  flex: 0 0 100%;
  margin-bottom: 16px;
}
.comp__contactForm .items .item input,
.comp__contactForm .items .item textarea {
  padding: 4px 16px;
  font-size: 16px;
  width: 100%;
  display: block;
  border-radius: 10px;
  color: #ddb7ab;
  resize: none;
  outline: none;
  border: none;
  transition: all 0.2s ease-in-out;
  background-color: #f4eeed;
  border: 3px solid #efd9d1;
}
.comp__contactForm .items .item input:hover,
.comp__contactForm .items .item textarea:hover {
  border-color: #f4eeed;
}
.comp__contactForm .items .item input:focus,
.comp__contactForm .items .item textarea:focus {
  border-color: #ddb7ab;
}
.comp__contactForm .items .item input[type="text"],
.comp__contactForm .items .item input[type="email"] {
  height: 40px;
}
.comp__contactForm .items .item .labels label {
  font-size: 16px;
  line-height: 1;
  display: block;
  color: #f4eeed;
  margin-bottom: 16px;
}
.comp__contactForm .items .item .labels label:after {
  content: '*';
  margin-left: 4px;
  color: red;
}
.comp__contactForm .items .item .labels .errorMessage {
  line-height: 1.5;
  font-size: 12px;
  color: red;
  margin-left: 6px;
}
.comp__contactForm .items .item.item--2cols {
  flex: 0 0 100%;
}
.comp__contactForm .btn.btn--lightWhite {
  padding: 16px 48px;
  margin-top: 16px;
}
@media (min-width: 768px) {
  .comp__contactForm .formTitle {
    font-size: 32px;
  }
  .comp__contactForm .items {
    flex-wrap: nowrap;
  }
  .comp__contactForm .items .item .labels label {
    font-size: 20px;
  }
  .comp__contactForm .items .item.item--2cols {
    flex: 0 0 50%;
    flex-basis: calc(50% - 16px);
  }
}
@media (min-width: 992px) {
  .comp__contactForm {
    margin: 16px 0 80px;
  }
}
/* PLUGINS */
.tpl__footer .tpl {
  background-color: #414342;
  padding: 16px 0;
}
.tpl__footer .tpl .content p,
.tpl__footer .tpl .content a {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #f4eeed;
}
.tpl__footer .tpl .content a:hover {
  color: #ffde22;
}
.tpl__footer .tpl .content .before {
  fill: #ffde22;
  margin: 0 8px;
}
.tpl__mainHeader .tpl {
  min-height: 87vh;
  background-size: cover;
  background-position: center;
}
.tpl__mainHeader .tpl:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to bottom, transparent, #ddb7ab);
  opacity: 0.5;
}
.tpl__mainHeader .tpl .heading {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 26vh;
  font-size: 30vw;
  opacity: 0.3;
  text-align: center;
  text-transform: lowercase;
}
.tpl__mainHeader .tpl .content {
  min-height: 65vh;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  position: absolute;
  right: 0;
  animation: animateText ease-out 1.5s;
}
.tpl__mainHeader .tpl .content .textContent {
  background-color: rgba(35, 37, 45, 0.5);
  padding: 16px 32px 16px 16px;
  border-radius: 8px;
}
.tpl__mainHeader .tpl .content .textContent .text {
  margin-bottom: 32px;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 1.3;
  color: #efd9d1;
  font-style: italic;
  width: 75%;
}
.tpl__mainHeader .tpl .content .textContent .text .delimiter {
  width: 250px;
  left: 0;
  margin-bottom: -12px;
}
.tpl__mainHeader .tpl .overlay {
  height: 180px;
}
@media (min-width: 500px) {
  .tpl__mainHeader .tpl .content .textContent {
    padding: 32px 64px 32px 32px;
  }
  .tpl__mainHeader .tpl .content .textContent .text {
    font-size: 24px;
  }
  .tpl__mainHeader .tpl .heading {
    bottom: 17vh;
  }
}
@media (min-width: 768px) {
  .tpl__mainHeader .tpl .content .textContent .text {
    font-size: 32px;
  }
  .tpl__mainHeader .tpl .heading {
    bottom: 20vh;
  }
}
@media (min-width: 992px) {
  .tpl__mainHeader .tpl .heading {
    bottom: 24vh;
  }
}
@media (min-width: 1200px) {
  .tpl__mainHeader .tpl .heading {
    bottom: 25vh;
  }
}
.tpl__about .picture {
  min-height: 400px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
}
.tpl__about .tpl {
  padding: 60px 0 160px;
  background-color: #f4eeed;
}
.tpl__about .tpl .descriptionWrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  flex-direction: column;
}
.tpl__about .tpl .descriptionWrapper .description {
  flex: 0 0 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tpl__about .tpl .descriptionWrapper .description .text {
  color: #414342;
  hyphens: none;
  font-weight: 600;
}
.tpl__about .tpl .textWrapper {
  margin: 64px 0;
  text-align: center;
}
.tpl__about .tpl .textWrapper .text.text--italic {
  font-style: italic;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
}
.tpl__about .tpl .iconsWrapper {
  flex-wrap: wrap;
}
.tpl__about .tpl .iconsWrapper .item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 50%;
  margin-bottom: 40px;
}
.tpl__about .tpl .iconsWrapper .item .icon {
  width: 70px;
  height: 70px;
}
.tpl__about .tpl .iconsWrapper .item .icon path {
  fill: #ddb7ab;
}
.tpl__about .tpl .iconsWrapper .item span {
  margin-top: 16px;
  font-size: 20px;
  font-weight: 700;
  color: #414342;
}
@media (min-width: 768px) {
  .tpl__about .tpl .descriptionWrapper {
    flex-direction: row;
    padding-top: 64px;
  }
  .tpl__about .tpl .textWrapper {
    margin: 64px 0 0;
  }
  .tpl__about .tpl .verticalDelimiter {
    width: 3px;
    background-color: #ddb7ab;
    margin: 12px 0 8px;
  }
  .tpl__about .tpl .verticalDelimiter.verticalDelimiter--smaller {
    display: inline-block;
    height: 100px;
  }
  .tpl__about .tpl .iconsWrapper .item .icon {
    width: 100px;
    height: 100px;
  }
}
@media (min-width: 992px) {
  .tpl__about .tpl {
    padding: 60px 0 160px;
  }
  .tpl__about .tpl .iconsWrapper {
    flex-wrap: nowrap;
  }
}
.tpl__services .tpl {
  padding: 0 0 180px;
  position: relative;
  background-color: #efd9d1;
}
.tpl__services .tpl .wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.tpl__services .tpl .wrapper .content {
  text-align: right;
}
.tpl__services .tpl .wrapper .description {
  padding: 16px 0;
  z-index: 1;
}
.tpl__services .tpl .wrapper .description .text {
  margin-top: 32px;
  font-weight: 600;
}
.tpl__services .tpl .wrapper .items {
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
}
.tpl__services .tpl .wrapper .items .item {
  flex: 0 0 100%;
  flex-basis: calc(100% - 32px);
  min-height: 260px;
  border-radius: 15px;
  padding: 24px;
  overflow: auto;
  margin: 32px 16px 0;
  box-shadow: inset 1px 1px 2px #efd9d1, inset -1px -1px 2px #c98e7b;
  transition: 0.25s all ease-in;
  background-color: #f4eeed;
}
.tpl__services .tpl .wrapper .items .item .bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0.4;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.tpl__services .tpl .wrapper .items .item .number {
  position: absolute;
  left: 12px;
  top: 40px;
  font-size: 120px;
  opacity: 0.07;
  color: #414342;
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
  letter-spacing: -8px;
}
.tpl__services .tpl .wrapper .items .item .content {
  padding: 16px 0;
}
.tpl__services .tpl .wrapper .items .item .content .title {
  color: #999b84;
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
}
.tpl__services .tpl .wrapper .items .item .content .text {
  text-align: center;
  color: #999b84;
  padding: 0 4px;
  hyphens: none;
  margin-top: 60px;
  font-weight: 600;
}
.tpl__services .tpl .wrapper .items .item:hover {
  transform: translateY(-20px);
}
@media (min-width: 500px) {
  .tpl__services .tpl .wrapper .items .item {
    flex: 0 0 50%;
    flex-basis: calc(50% - 32px);
  }
}
@media (min-width: 768px) {
  .tpl__services .tpl .wrapper {
    flex-direction: row;
  }
  .tpl__services .tpl .wrapper .description {
    padding: 16px 0;
  }
  .tpl__services .tpl .wrapper .items .item {
    flex: 0 0 50%;
    flex-basis: calc(50% - 32px);
    min-height: 250px;
  }
}
@media (min-width: 992px) {
  .tpl__services .tpl .wrapper .items {
    padding: 48px 0;
  }
  .tpl__services .tpl .wrapper .items .item {
    flex: 0 0 33%;
    flex-basis: calc(33% - 32px);
    min-height: 380px;
    padding: 16px;
  }
  .tpl__services .tpl .wrapper .items .item .content .title {
    font-size: 26px;
  }
}
@media (min-width: 1200px) {
  .tpl__services .tpl .wrapper .items .item {
    padding: 24px;
  }
  .tpl__services .tpl .wrapper .items .item .content .title {
    font-size: 32px;
  }
}
.tpl__pricelist .shape {
  background-color: #efd9d1;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  right: 20%;
  bottom: 4%;
  top: 8%;
  z-index: 5;
  border-radius: 14px;
  opacity: 0.4;
}
.tpl__pricelist .tpl {
  padding: 0 0 10px;
  background-color: #ddb7ab;
}
.tpl__pricelist .tpl .content {
  text-align: right;
}
.tpl__pricelist .tpl .description {
  padding: 32px 64px 32px;
}
.tpl__pricelist .tpl .description .text {
  margin-top: 32px;
}
.tpl__pricelist .tpl .items {
  display: flex;
  align-items: flex-start;
  margin: 64px 0;
  z-index: 7;
}
.tpl__pricelist .tpl .items .item {
  min-height: 200px;
}
.tpl__pricelist .tpl .items .item.item--desc {
  display: flex;
  justify-content: flex-end;
}
.tpl__pricelist .tpl .items .item.item--desc .text.text--right {
  text-align: right;
  color: #5e5b5a;
  text-transform: uppercase;
  white-space: inherit;
  font-family: 'Cormorant Garamond', serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2px;
  margin-bottom: 32px;
}
.tpl__pricelist .tpl .items ~ .items {
  margin-bottom: 0;
}
.tpl__pricelist .tpl .priceItems {
  display: flex;
  flex-direction: column;
}
.tpl__pricelist .tpl .priceItems .priceItem {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
  border-radius: 15px;
  padding: 24px;
  overflow: auto;
  box-shadow: inset 1px 1px 2px #c98e7b, inset -1px -1px 2px #e2c1b7;
  background-color: #efd9d1;
}
.tpl__pricelist .tpl .priceItems .priceItem .bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0.2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.tpl__pricelist .tpl .priceItems .priceItem .text {
  font-family: 'Cormorant Garamond', serif;
  color: #414342;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 0;
}
.tpl__pricelist .tpl .priceItems .priceItem .price {
  display: block;
  font-size: 22px;
  font-family: 'Cormorant Garamond', serif;
  font-weight: 600;
  color: #414342;
  margin-top: 16px;
}
@media (min-width: 768px) {
  .tpl__pricelist .shape {
    top: 12%;
    right: 33%;
  }
  .tpl__pricelist .tpl .priceItems .priceItem {
    flex-direction: row;
  }
  .tpl__pricelist .tpl .priceItems .priceItem .price {
    margin-top: 0;
  }
}
.tpl__contact {
  background-color: #ddb7ab;
}
.tpl__contact .tpl {
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #999b84;
}
.tpl__contact .tpl .bg {
  background-color: #999b84;
}
.tpl__contact .tpl .bg .content {
  padding: 0 32px;
}
.tpl__contact .tpl .bg .content .comp__heading {
  padding-top: 150px;
}
.tpl__contact .tpl .bg .content .contactInformation {
  padding: 16px 0;
}
.tpl__contact .tpl .bg .content .contactInformation .icon {
  fill: #f4eeed;
}
.tpl__contact .tpl .bg .content .contactInformation .icon path {
  stroke: #f4eeed;
}
.tpl__contact .tpl .bg .content .contactInformation .name {
  font-size: 32px;
  color: #efd9d1;
  margin-bottom: 32px;
  line-height: 1.3;
}
.tpl__contact .tpl .bg .content .contactInformation .address {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.tpl__contact .tpl .bg .content .contactInformation .address .text {
  margin-left: 16px;
}
.tpl__contact .tpl .bg .content .contactInformation .address .text p {
  color: #f4eeed;
}
.tpl__contact .tpl .bg .content .contactInformation .registrationNumber {
  margin: 0 0 24px 40px;
}
.tpl__contact .tpl .bg .content .contactInformation .registrationNumber p {
  color: #f4eeed;
}
.tpl__contact .tpl .bg .content .contactInformation .contact {
  display: inline-flex;
  flex-direction: column;
}
.tpl__contact .tpl .bg .content .contactInformation .contact .contactItem {
  display: flex;
  align-items: center;
}
.tpl__contact .tpl .bg .content .contactInformation .contact .contactItem .icon {
  fill: #efd9d1;
  margin-bottom: 8px;
}
.tpl__contact .tpl .bg .content .contactInformation .contact .contactItem .icon path {
  stroke: #efd9d1;
}
.tpl__contact .tpl .bg .content .contactInformation .contact .contactItem a {
  color: #efd9d1;
  margin-left: 16px;
  margin-bottom: 16px;
  transition: 0.2s color ease-in;
}
.tpl__contact .tpl .bg .content .contactInformation .contact .contactItem a:hover {
  color: #ddb7ab;
}
@media (min-width: 768px) {
  .tpl__contact .tpl .bg .content {
    padding: 0 32px 0 64px;
  }
  .tpl__contact .tpl .bg .content .contactInformation {
    padding: 64px 0 32px;
  }
}
.tpl__notFoundPage .tpl {
  background-color: #999b84;
}
.tpl__notFoundPage .tpl .content {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
}
.tpl__notFoundPage .tpl .content p {
  margin: 20px 0 70px;
  text-align: center;
  color: #efd9d1;
}
.tpl__notFoundPage .tpl .content .title {
  font-size: 128px;
  font-weight: 800;
  line-height: 1;
  font-family: 'Cormorant Garamond', serif;
  pointer-events: none;
  color: #f4eeed;
  padding: 0 20px;
}
.tpl__notFoundPage .tpl .content a {
  color: #efd9d1;
  border-bottom: 2px solid #efd9d1;
  margin-bottom: 16px;
  transition: 0.2s color ease-in, 0.2s border-color ease-in;
}
.tpl__notFoundPage .tpl .content a:hover {
  color: #ddb7ab;
  border-color: #ddb7ab;
}
@media (min-width: 768px) {
  .tpl__notFoundPage .tpl .content p {
    margin: 70px 0 15px;
  }
  .tpl__notFoundPage .tpl .content .title {
    font-size: 256px;
    letter-spacing: -4px;
  }
}
