.tpl__contact {
  background-color: @colorPink;
  .tpl {
    background-position: left; background-size: contain; background-repeat: no-repeat; background-color: @colorGreen;
    .bg {
      background-color: @colorGreen;
      .content {
        padding: 0 32px;
        .comp__heading { padding-top: 150px; }
        .contactInformation { 
          padding: 16px 0;
          .icon { 
            fill: @colorBeige;
            path { stroke: @colorBeige; }
          }
          .name { font-size: 32px; color: @colorLightPink; margin-bottom: 32px; line-height: 1.3; }
          .address {
            display: flex; align-items: center; margin-bottom: 24px;
            .text { margin-left: 16px;
              p { color: @colorBeige; }
             }
          }
          .registrationNumber {
            margin: 0 0 24px 40px;
            p { color: @colorBeige; }
          }
          .contact {
            display: inline-flex; flex-direction: column;
            .contactItem { 
              display: flex; align-items: center;
              .icon { fill: @colorLightPink; margin-bottom: 8px;
                path { stroke: @colorLightPink; }
               }
              a { color: @colorLightPink; margin-left: 16px;
                margin-bottom: 16px; transition: 0.2s color ease-in;
                &:hover { color: @colorPink;}
              }
             }
          }
         }
      }
    }
  }
  @media @media-m-min {
    .tpl {
      .bg .content {
        padding: 0 32px 0 64px;
        .contactInformation { padding: 64px 0 32px; }
      }
    }
  }
}