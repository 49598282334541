.tpl__about {
  .picture { min-height: 400px; position: absolute; left: 0; top: 0; bottom: 0; background-size: cover; background-repeat: no-repeat; background-position: center; width: 100%; }
  .tpl {
    padding: 60px 0 160px; background-color: @colorBeige;
    .descriptionWrapper {
      display: flex; justify-content: space-between; padding-top: 16px; flex-direction: column;
      .description { 
        flex: 0 0 45%; display: flex; flex-direction: column; justify-content: space-between;
        .text { color: @colorBlack; hyphens: none; font-weight: 600; }
      }
    }
    .textWrapper {
      margin: 64px 0; text-align: center;
     .text.text--italic { font-style: italic; font-weight: 700; font-size: 24px; text-align: center; }
   }
   .iconsWrapper {
    flex-wrap: wrap;
     .item {
       display: flex; align-items: center; justify-content: center; flex-direction: column; border-radius: 50%; margin-bottom: 40px;
      .icon { 
        width: 70px; height: 70px;
        path { fill: @colorPink; }
      }
      span { margin-top: 16px; font-size: 20px; font-weight: 700; color: @colorBlack; }
     }
   }
  }
  @media @media-m-min {
    .tpl {
      .descriptionWrapper { 
        flex-direction: row; padding-top: 64px;
      }
      .textWrapper { margin: 64px 0 0; }
      .verticalDelimiter { 
        width: 3px; background-color: @colorPink; margin: 12px 0 8px;
        &.verticalDelimiter--smaller { display: inline-block; height: 100px; }
      }
      .iconsWrapper {
        .item .icon { width: 100px; height: 100px; }
      }
    }
  }

  @media @media-l-min {
    .tpl { 
      padding: 60px 0 160px;
      .iconsWrapper { flex-wrap: nowrap; }
    }
  }
}