.btn {
  border: 0; outline: 0; display: inline-block; font-size: 16px; letter-spacing: 2px; border-radius: 16px; padding: 8px 16px;
  font-family: @font-family-default; font-weight: 700; color: @colorPink; transition: all 0.2s ease-in-out; cursor: pointer; text-transform: uppercase;

  &.btn--lightWhite {
    background-color: @colorBeige; font-family: @font-family-default; border: 3px solid @colorPink;
    &:hover {
      color: @colorBeige; background-color: @colorPink; border-color: @colorBeige;
    }
  }

  &.btn--cookie {
    padding: 8px 16px; color: @colorPink; margin: 0 16px;
  }

  &.btn--decline {
    font-size: 12px; border-bottom: 2px solid @colorPink; background-color: transparent; padding: 0; border-radius: 0; margin: 0 16px;
    &:hover { color: @colorLightPink; border-color: @colorLightPink; }
  }

  @media @media-m-min {
    padding: 16px 32px;
  }

  @media @media-l-min {
    font-size: 16px; padding: 12px 32px;
  }
}
