.tpl__services {
    .tpl {
        padding: 0 0 180px; position: relative; background-color: @colorLightPink;
        .wrapper {
            display: flex; align-items: center; flex-direction: column;
            .content { text-align: right; }
            .description { 
                padding: 16px 0; z-index: 1;
                .text { margin-top: 32px; font-weight: 600; }
            }
            .items {
                display: flex; flex-wrap: wrap; z-index: 1;
                .item {
                    flex: 0 0 100%; flex-basis: calc(100% - 32px); min-height: 260px; border-radius: 15px; padding: 24px; overflow: auto; margin: 32px 16px 0;
                    box-shadow: inset 1px 1px 2px @colorLightPink, inset -1px -1px 2px #c98e7b; transition: 0.25s all ease-in; background-color: @colorBeige;
                    .bg { position: absolute; left: 0; right: 0; bottom: 0; top: 0; opacity: 0.4; background-repeat: no-repeat; background-position: center; background-size: cover; }
                    .number { position: absolute; left: 12px; top: 40px; font-size: 120px; opacity: 0.07; color: @colorBlack; font-family: @font-family-third; font-weight: 700; letter-spacing: -8px;}
                    .content {
                        padding: 16px 0;
                        .title { color: @colorGreen; margin-bottom: 24px; font-weight: 700; font-size: 32px; text-align: center; }
                        .text { text-align: center; color: @colorGreen; padding: 0 4px; hyphens: none; margin-top: 60px; font-weight: 600; }
                    }
                    &:hover { transform: translateY(-20px); }
                }
            }
        }
    }
    @media @media-s-min {
        .tpl { 
            .wrapper {
                .items { 
                    .item { flex: 0 0 50%; flex-basis: calc(50% - 32px); }
                }
            }
        }
    }
    @media @media-m-min {
        .tpl { 
            .wrapper {
                flex-direction: row;
                .description { padding: 16px 0; }
                .items { 
                    .item {
                        flex: 0 0 50%; flex-basis: calc(50% - 32px); min-height: 250px;
                    }
                }
            }
        }
    }
    @media @media-l-min {
        .tpl { 
            .wrapper {
                .items {
                    padding: 48px 0;
                    .item {
                        flex: 0 0 33%; flex-basis: calc(33% - 32px); min-height: 380px; padding: 16px;
                        .content .title { font-size: 26px; }
                    }
                }
            }
        }
    }
    @media @media-xl-min {
        .tpl { 
            .wrapper {
                .items { 
                    .item {
                        padding: 24px;
                        .content .title { font-size: 32px; }
                    }
                }
            }
        }
    }
}