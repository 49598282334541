.tpl__pricelist {
    .shape { background-color: @colorLightPink; position: absolute; background-repeat: no-repeat; background-size: cover; left: 0; right: 20%; bottom: 4%; top: 8%; z-index: 5; border-radius: 14px; opacity: 0.4;  }
    .tpl {
        padding: 0 0 10px; background-color: @colorPink;
        .content { text-align: right; }
        .description { 
            padding: 32px 64px 32px;
            .text { margin-top: 32px; }
        }
        .items {
            display: flex; align-items: flex-start; margin: 64px 0; z-index: 7;
            .item { min-height: 200px; }
            .item.item--desc { display: flex; justify-content: flex-end;
                .text.text--right { text-align: right; color: #5e5b5a; text-transform: uppercase; white-space: inherit; font-family: @font-family-default;
                    font-size: 20px; font-weight: 600; letter-spacing: 2px; margin-bottom: 32px;
                 }
            }
        }
        .items ~ .items { margin-bottom: 0; }
        .priceItems {
            display: flex; flex-direction: column;
            .priceItem {
               display: flex; justify-content: space-between; flex-direction: column; align-items: center; margin-bottom: 32px; border-radius: 15px; padding: 24px; overflow: auto;
               box-shadow: inset 1px 1px 2px #c98e7b, inset -1px -1px 2px #e2c1b7; background-color: @colorLightPink;
               .bg { position: absolute; left: 0; right: 0; bottom: 0; top: 0; opacity: 0.2; background-repeat: no-repeat; background-position: center; background-size: cover; }
               .text { font-family: @font-family-default; color: @colorBlack; font-weight: 600; font-size: 22px; letter-spacing: 0; }
               .price { display: block; font-size: 22px; font-family: @font-family-default; font-weight: 600; color: @colorBlack; margin-top: 16px; }
            }
        }
    }
    @media @media-m-min {
        .shape { top: 12%; right: 33%; }
        .tpl { 
            .priceItems {
                .priceItem { 
                    flex-direction: row;
                    .price { margin-top: 0; }
                }
            }
        }
    }
}
