.comp__contactForm {
  margin: 32px 0;
  .formTitle { font-size: 24px; color: @colorBeige; line-height: 1.5; margin-bottom: 32px; }
  .items {
    display: flex; justify-content: space-between; flex-wrap: wrap;
    .item {
      flex: 0 0 100%; margin-bottom: 16px;
      input, textarea {
        padding: 4px 16px; font-size: 16px; width: 100%; display: block; border-radius: 10px; color: @colorPink;
        resize: none; outline: none; border: none; transition: all 0.2s ease-in-out; background-color: @colorBeige; border: 3px solid @colorLightPink;
        &:hover {
          border-color: @colorBeige;
        }
        &:focus {
          border-color: @colorPink;
        }
      }
      input[type="text"],
      input[type="email"] { height: 40px; }
      .labels {
        label { font-size: 16px; line-height: 1; display: block; color: @colorBeige; margin-bottom: 16px; }
        label:after { content: '*'; margin-left: 4px; color: red; }
        .errorMessage { line-height: 1.5; font-size: 12px; color: red; margin-left: 6px; }
      }
    }
    .item.item--2cols { flex: 0 0 100%; }
  }
  .btn.btn--lightWhite { padding: 16px 48px; margin-top: 16px; }

  @media @media-m-min {
    .formTitle { font-size: 32px; }
    .items {
      flex-wrap: nowrap;
      .item .labels label { font-size: 20px; }
      .item.item--2cols { flex: 0 0 50%; flex-basis: calc(50% - 16px); }
    }
  }

  @media @media-l-min {
    margin: 16px 0 80px;
  }
}