.tpl__notFoundPage {
  .tpl {
    background-color: @colorGreen;
    .content {
      display: flex; align-items: center; justify-content: center; min-height: 100vh; flex-direction: column;
      p { margin: 20px 0 70px; text-align: center; color: @colorLightPink; }
      .title { font-size: 128px; font-weight: 800; line-height: 1; font-family: @font-family-default; pointer-events: none;
        color: @colorBeige; padding: 0 20px; }
        a { color: @colorLightPink; border-bottom: 2px solid @colorLightPink;
          margin-bottom: 16px; transition: 0.2s color ease-in, 0.2s border-color ease-in;
          &:hover { color: @colorPink; border-color: @colorPink; }
        }
    }
  }

  @media @media-m-min {
    .tpl {
      .content {
        p { margin: 70px 0 15px; }
        .title { font-size: 256px; letter-spacing: -4px; }
      }
    }
  }
}