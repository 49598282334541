.comp__heading {
  display: inline-block; margin-bottom: 16px;
  .heading { display: inline-block; font-family: @font-family-default; font-size: 36px; line-height: 1.1; margin: 0; margin-bottom: 12px; text-transform: uppercase;
    animation: fade-in 1.5s both;
   }
  .heading.heading--pink { color: @colorPink; }
  .heading.heading--beige { color: @colorBeige; }
  .heading.heading--green { color: @colorGreen; }
  .heading.heading--lightPink { color: @colorLightPink; }
  @media @media-m-min {
    margin-bottom: 0;
    .heading { font-size: 48px; margin-bottom: 32px; }
  }
  @media @media-l-min {
    .heading { font-size: 60px; }
  }
}